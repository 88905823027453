import React from 'react'

const Certificate = () => {
  return (

<div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
           
            <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6 border border-gray-300">
            <p className='-mt-3 text-sm mb-10'> Reg :- NH-UP556536526HK</p>
               
                <img
                    src="https://res.cloudinary.com/dkmsgd3ua/image/upload/v1731944795/Asset_12_4x_dsjv2z.png"
                    alt="logo"
                    className="h-16 w-auto m-auto mb-6"
                />
                <div className="flex justify-center text-center mb-8">
                    <div>
                        <h2 className="text-4xl font-bold text-teal-600 my-1">Sankalp Social Trust</h2>
                        <p className="text-sm text-gray-500">Tetari Bazar Siddharth Nagar Uttar Pradesh - 272207</p>
                        <h3 className="text-3xl font-semibold text-gray-800 mt-2"><i>Donation Certificate</i></h3>
                        
                    </div>
                </div>
                <div className="text-left mb-8">
                    <p className="text-lg text-gray-800">Dear <strong className="text-teal-600">Karan</strong>,</p>
                    <p className="text-lg text-gray-600 mt-2">
                        Thank you for your generosity! On behalf of <strong className="text-teal-600">Sankalp Social Trust</strong>, we want to express our heartfelt gratitude for your generous support. Your recent donation of <br/> <strong className="text-teal-600">₹ 600</strong> has made a significant impact on our mission.
                    </p>
                </div>
                <div className="text-left mb-8">
                    <table className="w-full border-collapse mx-auto text-left">
                        <tbody>
                            <tr>
                                <td className="border-2 border-gray-300 text-lg text-gray-600 p-2 font-semibold">Donation Date:</td>
                                <td className="border-2 border-gray-300 text-lg text-gray-600 p-2">12/11/2024</td>
                            </tr>
                            <tr>
                                <td className="border-2 border-gray-300 text-lg text-gray-600 p-2 font-semibold">Donation Amount:</td>
                                <td className="border-2 border-gray-300 text-lg text-gray-600 p-2">₹ 600</td>
                            </tr>
                            <tr>
                                <td className="border-2 border-gray-300 text-lg text-gray-600 p-2 font-semibold">Payment Id:</td>
                                <td className="border-2 border-gray-300 text-lg text-gray-600 p-2">8659</td>
                            </tr>
                            <tr>
                                <td className="border-2 border-gray-300 text-lg text-gray-600 p-2 font-semibold">Event Id:</td>
                                <td className="border-2 border-gray-300 text-lg text-gray-600 p-2">w475</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p className="text-lg text-blue-800 text-center my-8">www.sankalpsocialtrust.org</p>
                <div className="flex justify-center mt-12">
                    <div className="text-center">
                        <p className="text-md text-gray-800">Digitally Signed</p>
                        <div className="mt-4 h-16 border-t-2 border-gray-300"></div>
                    </div>
                </div>
            </div>
        </div>
        

  )
}

export default Certificate