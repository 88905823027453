
import axios from "axios";
import React, { useEffect, useState } from "react";


const Volunteer = () => {
  const [alluser,setAlluser]=useState()
  const [loader,setLoader]=useState(false)
  const getAllUsers=async()=>{
    setLoader(true)
    await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/getallusers`)
    .then((res)=>{
        
        const volunteerUser=res?.data?.filter(item => item?.isVolunteer===true)
         setAlluser(volunteerUser)
         setLoader(false)
    }).catch((error)=>{
      console.log(error)
      setLoader(false)
    })
   
  }

  useEffect(()=>{
    getAllUsers()
  },[])
  return (
    <>
    <div className="text-center font-bold text-lg text-gray-300 bg-teal-700 ">
        <p className="p-1">Our Dedicated Team</p>
    </div>
    <p className='font-bold text-center text-md text-blue-500 p-2'>{loader?"Loading...":""}</p>
    <div className="grid grid-cols-1 text-center gap-2 m-1 md:grid-cols-4 lg:grid-cols-4">
     {
      alluser?.map((res,i)=>(
        <div className="bg-gray-100 shadow-md rounded-sm cursor-pointer my-1">
        <img
          src={res.profile}
          alt="img"
          className="h-32 w-32 rounded-md m-auto flex justify-center lg:h-32 lg:w-32 mt-1"
        />
        <div className="my-3">
        <div className="text-start ml-12">
        <p className="mt-2 uppercase text-sm font-semibold text-gray-600">
          NAME : {res?.name}
          </p>
          <p className="mt-2 text-sm font-semibold text-gray-600">
          ID No : {res?.volunteerId}
          </p>
          <p className="mt-2 text-sm font-semibold text-gray-600">
          DOB : {res?.dob}
          </p>
          <p className="mt-2 uppercase text-xs font-semibold text-gray-600">
          EMAIL : {res?.email}
          </p>
        </div>
         
          <img
          src="/img/success.png"
          alt="img"
          className="h-16 w-16 rounded-md p-2 m-auto flex justify-center mt-1"
        />
         <p className="mt-1 text-sm font-semibold text-green-600">
         Verified 
          </p>
          {/* <i className="text-xs font-semibold text-blue-600">
            {res.isAdmin?"Admin":"Member"}
          </i> */}
          <div className="flex justify-between items-center mt-4">
          <a href={res?.socialMedia?.facebook|| '#'} target="_blank" rel="noopener noreferrer" >
            <img
              src="./img/icons/f.png"
              alt="logo"
              className="h-3 cursor-pointer mx-2"
            />
            </a>
       
            <a href={res?.socialMedia?.youtube|| '#'} target="_blank" rel="noopener noreferrer" >
            <img
              src="./img/icons/y.png"
              alt="logo"
              className="h-3 cursor-pointer mx-2"
            />
            </a>
            <a href={res?.socialMedia?.linkedin|| '#'} target="_blank" rel="noopener noreferrer" >
            <img
              src="./img/icons/i.png"
              alt="logo"
              className="h-3 cursor-pointer mx-2"
            />
            </a>
            <a href={res?.socialMedia?.x|| '#'} target="_blank" rel="noopener noreferrer" >
            <img
              src="./img/icons/x.png"
              alt="logo"
              className="h-3 cursor-pointer mx-2"
            />
            </a>
          </div>
        </div>
      </div>
      ))
     }
     
     
      
      
      
    </div>
    
    
    </>
  );
};

export default Volunteer;
