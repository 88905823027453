


import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ThankYou = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [donorData, setDonorData] = useState({ name: "", amount: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const pdfFun = async (userData) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/pdf-converter`,
        { userData }
      );
   

      setTimeout(() => {
        navigate("/");
    }, 3000); // 5000 milliseconds = 5 seconds
    

     
    } catch (err) {
      console.error("PDF generation error:", err);
      setError("Failed to generate PDF. Please try again later.");
    }
  };

  const getPaymentDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/show-donation`
      );
      const donor = response.data.data.find(item => item._id === id);

      if (donor) {
        setDonorData({
          name: donor.donarName,
          amount: donor.donatedAmount,
        });

        function customFormatDate(dateString) {
          const date = new Date(dateString);
          
          // Format the date components
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
          const day = String(date.getDate()).padStart(2, '0');
          const year = date.getFullYear();
          
          // Format the time components
          let hours = date.getHours();
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine AM or PM
          hours = hours % 12; // Convert to 12-hour format
          hours = hours ? String(hours).padStart(2, '0') : '12'; // Display '12' instead of '0'
      
          // Return formatted date and time
          return `${day}/${month}/${year} ${hours}: ${minutes} ${ampm}`; // Format: DD/MM/YYYY Time: HH:MM AM/PM
      }
      
      const formattedDate = customFormatDate(donor.date);
      


        pdfFun({
          name: donor.donarName,
          email: donor.donarEmail,
          amount: donor.donatedAmount,
          donationDate: formattedDate,
          paymentId: donor.razorpay_payment_id,
          eventId: donor.eventId,
          id: donor._id,
        });
      } else {
        setError("Donor not found.");
      }
    } catch (err) {
      console.error("Error fetching payment details:", err);
      setError("Failed to fetch payment details. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div
      className="relative flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('/img/icons/thanks.gif')" }}
    >
      <div className="relative max-w-md mx-auto p-6 bg-gradient-to-r from-gray-200 to-green-300 shadow-lg rounded-lg text-center z-10">
        <h1 className="text-3xl font-bold text-green-600 mb-2">
          Thank You for Your Kindness!
        </h1>
        <h3 className="text-xl text-cyan-700 font-semibold mb-2">{donorData?.name}</h3>
        <p className="text-lg text-gray-700 mb-4">
          Thank you for your generous donation of ₹<b>{donorData?.amount}</b>. Your support greatly advances our mission at <b>Sankalp Social Trust</b>.
          We deeply appreciate your commitment.
        </p>
        <p className="text-[12px] text-gray-600">
          Your Donation Certificate has been sent to your email!
        </p>
        <a href="/" className="text-blue-500 font-semibold hover:text-blue-700">
          Return to Homepage
        </a>
      </div>
    </div>
  );
};

export default ThankYou;
