





import axios from "axios";
import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";

const Health = () => {
  const [allevent, setAllevent] = useState();
  const [loader,setLoader]=useState(false)

  const getAllEventFun = async () => {
    setLoader(true)
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/get-all-child-education`);
      const activeEvent = res.data?.filter(
        (item) => item?.status === true && item?.category === "healthEmergency"
      );
      setAllevent(activeEvent);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  
 

  useEffect(() => {
    getAllEventFun();
  }, []);
  

  return (
    <>

    {allevent && allevent?.length > 0? <div className="text-center text-lg font-bold mb-1 text-gray-300 bg-teal-700 py-2">
        <p>Donate For Medical Emergency </p>
      </div>:""}
    
      <p className='font-bold text-center text-md text-blue-500 p-2'>{loader?"Loading...":""}</p>
      <div className="grid grid-cols-1 text-start gap-2 m-1 md:grid-cols-4">
        {allevent?.map((res, i) => (
          <div
         
            key={i}
            className="bg-gray-100 shadow-md rounded-sm cursor-pointer my-1"
          >
            <Link to={`/details-page/${res?._id}`}>
            <img
              src={res?.image[0]}
              alt={`img-${i}`}
              className="hover:opacity-80 h-32 lg:h-60 w-full"
            />
            </Link>
  
            <div className="my-3">
              <p className="my-1 ml-3 text-md font-semibold text-gray-600">
                Name : {res?.name}
              </p>
              <p className="my-1  ml-3 text-md font-semibold text-gray-600">
                Fathers : {res?.fatherName}
              </p>
              <p className="my-1  ml-3 text-md font-semibold text-gray-600">
                Age : {res?.age}y
              </p>
              <p className="my-1  ml-3 text-md font-semibold text-gray-600">
                Address : {res?.fatherName}
              </p>
              <p className="my-1  ml-3 text-sm font-semibold  text-teal-700">
                <span className="font-bold">Create Date :- </span>
                {dateFormat(res?.createdAt, "dddd, mmmm dS, yyyy")}
              </p>

             <div className="flex justify-around items-center">
                
                 <Link
                to={`/payment1/${res?._id}`}
                className="bg-red-500 text-center font-semibold cursor-pointer p-1 px-2 rounded-sm text-white mt-3 hover:bg-red-600"
              >
                Donate Now
              </Link>
              <p className="bg-red-500 text-center font-semibold cursor-pointer p-1 px-2 rounded-sm text-white mt-3 hover:bg-red-600"><a href="https://www.youtube.com/@sankalpsocialtrust" target='_blank'>Watch Video</a> </p>
                
             </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Health;
