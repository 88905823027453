
import axios from 'axios'
import React, { useState } from 'react'
import { Link,useNavigate} from 'react-router-dom'



const ForgetPass =() => {
  const [loading,setLoading]=useState(false)
  const [showmessage,setmessage]=useState('')
  const [status,setStatus]=useState()
  const navigate = useNavigate();
  const [email,setEmail]=useState(null)

 

  const handleForgotPassword = async () => {
    const passwordCredential = { email };
  
    setLoading(true);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/forgotPassword`, passwordCredential);
      setEmail('');  // Reset email input after successful request
      setmessage(response.data.message)
      setStatus(response.status)
    } catch (error) {
      console.error('Error resetting password:', error);
      setmessage(error.response.data.message)
      setStatus(error.response.status)
      // Optionally, you can display an error message to the user here.
    } finally {
      setLoading(false);  // Stop loading regardless of success or failure
    }
  };
  

  const handleEmailVerify=async()=>{
    const emailCredential = { email };
  
    setLoading(true);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/resendVerificationEmail`, emailCredential);
      setEmail('');  // Reset email input after successful request
      setmessage(response.data.message)
      setStatus(response.status)
      console.log("response",response)
    } catch (error) {
      console.error('Error resetting password:', error);
      setmessage(error.response.data.message)
      setStatus(error.response.status)
      // Optionally, you can display an error message to the user here.
    } finally {
      setLoading(false);  // Stop loading regardless of success or failure
    }


    console.log("handleEmailVerify")
    
  }

  const handlsubmit=async(e)=>{
      e.preventDefault()
     
  }
  return (
    <>
  <Link to="/login" class="inline-block align-baseline font-bold text-sm ml-3 text-gray-700 hover:text-blue-800" href="#">
       Back
     </Link>
   <div className='w-full max-w-xs m-auto mt-6'>
   <div className='text-center font-bold text-lg text-teal-800'>Forget Password & Verify Email</div>
   
   <form onSubmit={handlsubmit} className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>

   <div className="mb-4">
     <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Email">
     Email
     </label>
     <input onChange={(e)=>setEmail(e.target.value)} value={email} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="email" placeholder="Valid Email" />
   </div>
   {status==400?<p className={`text-center text-gray-200 font-semibold ${status==400?"bg-red-600":""} p-2 my-2 rounded-sm`}>{showmessage}</p>:""} 
    {status==200?<p className={`text-center text-gray-200 font-semibold ${status==200?"bg-green-700":""} p-2 my-2 rounded-sm`}>{showmessage}</p>:""} 

   
  {loading?<p className='text-center text-gray-700 font-semibold bg-blue-300 p-2 my-2 rounded-sm'>Please Wait...</p>:""}
   <div class="flex items-center justify-between">
     <button onClick={()=>handleForgotPassword()} class="bg-teal-600 hover:bg-blue-700 text-sm text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline m-0.5">
       Forget Password
     </button>
     <button onClick={()=>{handleEmailVerify()}} class="bg-teal-600 hover:bg-blue-700 text-sm text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline m-0.5">
       Verify Email
     </button>
   
   </div> 
         
 

   </form>
   </div>
   
    
    
    </>
  )
}

export default ForgetPass