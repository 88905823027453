import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Join from "./component/Join";
import Navbar from "./component/Navbar";
import Login from "./component/Login";
import Home from "./component/Home";
import Event from "./component/Event";
import Gallery from "./component/Gallery";
import GalleryDetail from "./component/GalleryDetail";
import Member from "./component/Member";
import Paymen from "./component/Paymen";
import CreateBlog from "./component/user/CreateBlog";
import ManageBlog from "./component/user/ManageBlog";
import AllBlogs from "./component/AllBlogs";
import BlogDetail from "./component/BlogDetail";
import Allpost from "./component/admin/Allpost";
import Allusers from "./component/admin/Allusers";
import CreateEvent from "./component/admin/CreateEvent";
import EventDetails from "./component/admin/EventDetails";
import ManageEvent from "./component/admin/ManageEvent";
import EditEvent from "./component/admin/EditEvent";

import { MembershipDetails } from "./component/profile/MembershipDetails";
import AdminProtected from "./component/protected/AdminProtected";
import AdminProfile from "./component/admin/AdminProfile";
import EmailVerify from "./component/EmailVerify";
import ChildEducation from "./component/work/ChildEducation";
import ChildEducationDetails from "./component/work/ChildEducationDetails";
import CreateChildEducation from "./component/work/CreateChildEducation";
import ChildEducationPage from "./component/work/ChildEducationPage";
import OrphanChild from "./component/work/orphan/OrphanChild";
import HomelessPeople from "./component/work/homeless/HomelessPeople";
import WeddingPoor from "./component/work/wedding/WeddingPoor";
import Details from "./component/work/detailsPage/Details";
import OrphanChildAdmin from "./component/admin/ourservices/OrphanChildAdmin";
import ChildEducationAdmin from "./component/admin/ourservices/ChildEducationAdmin";
import HomelessAdmin from "./component/admin/ourservices/HomelessAdmin";
import WeddingAdmin from "./component/admin/ourservices/WeddingAdmin";
import Volunteer from "./component/Volunteer";
import EditMemberByAdmin from "./component/admin/EditMemberByAdmin";
import EditSocialWork from "./component/admin/EditSocialWork";
import UpdateUserProfile from "./component/UpdateUserProfile";
import Payment1 from "./component/Payment1";
import Health from "./component/work/health/Health";
import HealthAdmin from "./component/admin/ourservices/HealthAdmin";
import ThankYou from "./component/ThankYou";
import AllDonation from "./component/AllDonation";
import AdminAllDonation from "./component/admin/AdminAllDonation";
import UserDeatilByAdmin from "./component/admin/UserDeatilByAdmin";
import About from "./component/About";
import EditBlog from "./component/admin/EditBlog";
import ChangePass from "./component/ChangePass";
import ContactUs from "./component/ContactUs";
import ThanksMessage from "./component/ThanksMessage";
import Error from "./component/Error";
import AllMesaaage from "./component/AllMesaaage";
import Certificate from "./component/Certificate";
import Team from "./component/Team";
import TeamPersonDetail from "./component/TeamPersonDetail";
import ForgetPass from "./component/ForgetPass";
import ResetPassword from "./component/ResetPassword";



function App() {
  return (
    <Router>
    <Navbar/>
    
  <Routes>
     

    <Route exact path="/" element={<Home />} />
     <Route exact path="/join" element={<Join />} />
     <Route exact path="/login" element={<Login />} />
     <Route exact path="/event" element={<Event />} />
     <Route exact path="/gallery" element={<Gallery />} />
     <Route exact path="/gallerydetail" element={<GalleryDetail/>}/>
     <Route exact path="/member" element={<Member/>}/>
     <Route exact path="/contactus" element={<ContactUs/>}/>
     <Route exact path="/volunteer" element={<Volunteer/>}/>
     <Route exact path="/payment/:id" element={<Paymen/>}/>
     <Route exact path="/payment1/:id" element={<Payment1/>}/>
     <Route exact path="/createblog" element={<CreateBlog/>}/>
     <Route exact path="/editBlog/:id" element={<EditBlog/>}/>
     <Route exact path="/change/password" element={<ChangePass/>}/>
     <Route exact path="/team" element={<Team/>} /> 
     <Route exact path="/team/details/:id" element={<TeamPersonDetail/>} /> 
    
     <Route exact path="/certificate" element={<Certificate/>} />   {/* dummy design for send certificate by email this code for expressjs */}
     
     
     <Route exact path="/manageblog" element={<ManageBlog/>}/>
     <Route exact path="/allblogs" element={<AllBlogs/>}/>
     <Route exact path="/blogdetails/:id" element={<BlogDetail/>}/>
     {/* <Route exact path="/profile" element={<Profile/>}/> */}
     <Route exact path="/membershipdetails" element={<MembershipDetails/>}/>
     
     <Route exact path="/verify/:id/:token" element={<EmailVerify/>}/>
     <Route exact path="/re-setpassword/:id/:token" element={<ResetPassword/>}/>

     <Route exact path="/admineventails/:id" element={<EventDetails />}/>
     <Route exact path="/details-page/:id" element={<Details />}/>

     <Route exact path="/child-eduction" element={<ChildEducation />}/>
     <Route exact path="/orphan-child" element={<OrphanChild />}/>
     <Route exact path="/homeless-people" element={<HomelessPeople />}/>
     <Route exact path="/wedding-poor" element={<WeddingPoor />}/>
     <Route exact path="/emergency-health" element={<Health/>}/>
     <Route exact path="/all-donation" element={<AllDonation />}/>
   
     <Route exact path="/child-eduction-details/:id" element={<ChildEducationDetails />}/>
     <Route exact path="/update-user-profile" element={<UpdateUserProfile />}/>
     <Route exact path="/thank-you/:id" element={<ThankYou />}/>
     <Route exact path="/thank/message" element={<ThanksMessage />}/>
     <Route exact path="/about" element={<About/>}/>
     <Route exact path="/forget-password" element={<ForgetPass/>}/>
     
     


     {/* --------------------------------admin------------------------------------- */}

 
     <Route exact path="/profile" element={<AdminProtected AdminProfile={AdminProfile} />}/>
     <Route exact path="/adminallpost" element={<AdminProtected AdminProfile={Allpost}/>}/>
     <Route exact path="/adminallusers" element={<AdminProtected AdminProfile={Allusers}/>}/>
     <Route exact path="/admincreatevent" element={<AdminProtected AdminProfile={CreateEvent} />}/>
    
     <Route exact path="/adminmanageevent" element={<AdminProtected AdminProfile={ManageEvent} />}/>
     <Route exact path="/admineditevent/:id" element={<AdminProtected AdminProfile={EditEvent} />}/>

     
     <Route exact path="/child-education-page" element={<AdminProtected AdminProfile={ChildEducationPage} />}/>
     <Route exact path="/create-child-education" element={<AdminProtected AdminProfile={CreateChildEducation} />}/>

     <Route exact path="/orphan-child-admin" element={<AdminProtected AdminProfile={OrphanChildAdmin} />}/>
     <Route exact path="/child-education-admin" element={<AdminProtected AdminProfile={ChildEducationAdmin} />}/>
     <Route exact path="/homeless-people-admin" element={<AdminProtected AdminProfile={HomelessAdmin} />}/>
     <Route exact path="/wedding-admin" element={<AdminProtected AdminProfile={WeddingAdmin} />}/>
     <Route exact path="/edit-health-admin" element={<AdminProtected AdminProfile={HealthAdmin} />}/>
     <Route exact path="/edit-member-admin/:id" element={<AdminProtected AdminProfile={EditMemberByAdmin} />}/>
     <Route exact path="/edit-social-work-admin/:id" element={<AdminProtected AdminProfile={EditSocialWork} />}/>
     <Route exact path="/admin-all-donation" element={<AdminProtected AdminProfile={AdminAllDonation} />}/>
     <Route exact path="/view-member-details-admin/:id" element={<AdminProtected AdminProfile={UserDeatilByAdmin} />}/>
     <Route exact path="/message/notification" element={<AdminProtected AdminProfile={AllMesaaage} />}/>
     <Route path="*" element={<Error />} />

   

  </Routes>
  </Router>
   
  );
}

export default App;
