

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link,useNavigate, useParams} from 'react-router-dom'



const ResetPassword =() => {
    const {token } = useParams(); 
    
  const [loading,setLoading]=useState(false)
  const [message,setMessage]=useState('')
  const [status,setStatus]=useState()
  const navigate = useNavigate();
  const [confirmPassword,setConfirmPassword]=useState(null)
  const [password,setPassword]=useState(null)
 
useEffect(() => {
    const logoutFun = () => {
        localStorage.clear();
      };
      logoutFun()
},[])
 

  const handlsubmit=async(e)=>{
      e.preventDefault()

      const loginCredential = {
        password,
        confirmPassword,
        token
    };
    
    setLoading(true);
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/resetPassword`, loginCredential);
        // Handle success response here (you can do something with `res` if needed)
    
        setLoading(false);
       setTimeout(()=>{
        navigate("/login")
       },2000)
    } catch (error) {
        setLoading(false);
        // Check if error.response is available and has data, else provide a fallback message
        if (error.response) {
            setMessage(error.response.data.message || 'An error occurred');
            setStatus(error.response.status || 500);
        } else {
            // Handle errors that aren't related to the response, like network issues
            setMessage('Network error or server is unreachable');
            setStatus(500);
        }
    }
    
  }
  return (
    <>

   <div className='w-full max-w-xs m-auto mt-6'>
   <div className='text-center font-bold text-lg text-teal-800'>Create New Password</div>
   
   <form onSubmit={handlsubmit} className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>

   <div className="mb-4">
     <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Email">
     New Password
     </label>
     <input onChange={(e)=>setPassword(e.target.value)} value={password} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="password" placeholder="New Password" />
   </div>
   {status==400?<p className={`text-center text-gray-200 font-semibold ${status==400?"bg-red-600":""} p-2 my-2 rounded-sm`}>{message}</p>:""} 
    {status==200?<p className={`text-center text-gray-200 font-semibold ${status==200?"bg-green-700":""} p-2 my-2 rounded-sm`}>{message}</p>:""} 
   <div className="mb-4">
     <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
     Confirm New Password
     </label>
     <input onChange={(e)=>setConfirmPassword(e.target.value)} value={confirmPassword} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="password" placeholder="Confirm New Password" />
   </div>
   
  {loading?<p className='text-center text-gray-700 font-semibold bg-blue-300 p-2 my-2 rounded-sm'>Please Wait...</p>:""}
   <div class="flex items-center justify-between">
     <button class="bg-teal-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
       Update Password
     </button>
 
   </div> 
         
 

   </form>
   </div>
   
    
    
    </>
  )
}

export default ResetPassword