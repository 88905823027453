import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// Updated teamMembers with social media links
const teamMembers = [
  {
    id: 0,
    name: "Karan",
    role: "Founder",
    image: "/img/about/karan.jpeg",
    Qualifications: "Master of Computer Applications",
    Specialization: "Web Development (MERN)",

    bio: [
      {
        p1: `In the heart of Uttar Pradesh, a small town pulsed with life and stories. Among its residents was Karan Sahani, 
                a young man whose journey was marked by passion, perseverance, and a profound sense of purpose. Raised in a 
                modest family, Karan was always encouraged to pursue education as a means to a better life. His parents 
                instilled in him the values of hard work and compassion, shaping him into the person he would later become.`,
      },
      {
        p2: `Karan's academic journey took a pivotal turn when he enrolled at the University of Delhi. Immersed in the 
                vibrant atmosphere of the university, he found his true calling in the world of technology. He pursued a 
                postgraduate degree in Computer Science, where his natural aptitude for problem-solving and creativity flourished. 
                Karan spent countless hours learning the intricacies of web development, enamored by the potential technology 
                had to connect people and make a difference in the world.`,
      },
      {
        p3: ` Upon graduation, armed with a wealth of knowledge and fierce determination, Karan embarked on a career as a 
                web developer. He quickly established himself in the industry, working with various companies and honing his 
                skills. However, amidst the success and growth in his professional life, Karan felt a nagging void. He realized 
                that while he was building websites and applications that served businesses, there was a greater need outside 
                his office walls—one that called for his attention and action.`,
      },
      {
        p4: `   Driven by a desire to create a lasting impact, Karan took a bold step. He founded the Sankal Social Trust, an 
                initiative aimed at uplifting the less fortunate in his community. The name "Sankal" means "resolve" or 
                "determination," a fitting tribute to Karan's unwavering commitment to social change. His vision was clear: to 
                raise funds and distribute essential resources to the poor and needy, ensuring they received food, clothes, 
                books, and health emergency assistance.`,
      },
      {
        p5: `Karan dedicated his evenings and weekends to the trust, tirelessly organizing fundraisers and community events. 
                He reached out to friends, family, and local businesses, sharing his mission and inspiring others to join his 
                cause. The response was overwhelming; people from all walks of life rallied behind him, eager to contribute 
                their time, resources, and expertise.`,
      },
      {
        p6: ` With each passing month, the Sankal Social Trust grew in reach and impact. Karan and his team distributed meals 
                to hungry families, provided warm clothes during the cold winters, and organized book drives for children in 
                need, igniting a love for learning in young hearts. They even set up a health emergency fund to assist those 
                facing dire medical situations. Karan’s work transformed lives, creating a ripple effect of kindness and support 
                throughout the community.`,
      },
      {
        p7: ` As Karan navigated the challenges of running a nonprofit, he leveraged his skills as a web developer to create 
                a website for the Sankal Social Trust. The platform not only showcased their work but also served as a hub for 
                donations, volunteer sign-ups, and community engagement. Karan’s innovative approach blended technology and 
                social good, allowing the trust to expand its reach and efficiency.`,
      },
      {
        p8: ` Through his journey, Karan learned valuable lessons about resilience, empathy, and the power of community. His 
                story became a source of inspiration for many, proving that one person’s determination could spark a movement 
                of kindness and change. Karan Sahani wasn’t just a web developer or the founder of a trust; he became a beacon 
                of hope for the needy, a leader who transformed compassion into action.`,
      },
      {
        p9: `   Years later, as he looked back at his journey, Karan felt a profound sense of fulfillment. He knew that every 
                meal shared, every book donated, and every life touched was a testament to the power of resolve. In the quiet 
                moments of reflection, he understood that true success lies not in personal achievements, but in the ability 
                to uplift others, creating a legacy of love and compassion for generations to come.`,
      },
    ],
    social: {
      facebook: "https://facebook.com/karan",
      twitter: "https://twitter.com/karan",
      linkedin: "https://linkedin.com/in/karan",
      github: "https://github.com/kp505sdr",
    },
  },
  {
    id: 2,
    name: "Chanda",
    role: "Treasurer",
    image: "/img/about/chanda1.jpg",
    Qualifications: "Master of Arts",
    Specialization: "Graphics Designing",
    bio: [
      {
        p1: `Thecity of Gorakhpur, there lived a woman named Chanda Sahani, whose life was a perfect blend of creativity and compassion. A graphic designer by profession, she had spent years mastering her craft in the fast-paced environment of a multinational corporation (MNC), but her heart was always drawn to a deeper calling—helping the less fortunate.`,
      },
      {
        p2: `Chanda had always been a dreamer. From an early age, she was captivated by the beauty of design—the way colors, shapes, and patterns could evoke emotions and tell stories. Her passion for art led her to pursue a postgraduate degree in graphic design, which opened the doors to a successful career. Working with top-tier brands and multinational companies, Chanda created stunning visual campaigns, advertisements, and logos that earned her a well-respected reputation in the industry.`,
      },
      {
        p3: `But as the years went by, she began to feel a subtle emptiness. While her career flourished, her soul yearned for something more meaningful. It was during this time that Chanda realized her true calling lay not just in creating beautiful designs but in using her skills to make a difference in the lives of others.`,
      },
      {
        p4: `Her desire to help those in need had always been a part of her. Since childhood, she had witnessed the struggles of the poor and underprivileged around her. Whether it was seeing children without access to education or families living in poverty, Chanda's heart ached for them. She knew that the world was full of talent and opportunities, yet so many people lacked the support they needed to reach their full potential.`,
      },
      {
        p5: `One day, while browsing through social media, she came across an advertisement for an NGO called Sankalp Social Trust. The organization was dedicated to improving the lives of underprivileged children, offering them education, healthcare, and support for a better future. The message resonated deeply with Chanda, and without a second thought, she reached out to offer her help.`,
      },
      {
        p6: `At first, Chanda was hesitant about how her design skills could contribute to such a noble cause. But she soon realized the power of her expertise. She began designing impactful campaigns for Sankalp, creating flyers, brochures, and social media posts that helped raise awareness and funds for the organization. Her creativity, once used for corporate clients, was now being put to work to give a voice to the voiceless and bring hope to those who had none.`,
      },
      {
        p7: `In her free time, Chanda actively volunteered at Sankalp, teaching children basic design principles and showing them how they, too, could express themselves through art. The children, once withdrawn and disheartened by their circumstances, began to find joy in their creations. For Chanda, this was the most rewarding part of her work—the transformation she witnessed in the children as they gained confidence, hope, and a sense of purpose.`,
      },
      {
        p8: `Chanda's story was not just about a career in design but about a life dedicated to service. Despite her demanding job at the MNC, she always made time for the less fortunate, whether it was through her work at Sankalp or by personally supporting the needs of the poor in her community. Her hobby of helping others had become a vital part of her identity.`,
      },
      {
        p9: `As Chanda continued her journey, she realized that life was not just about success or wealth. It was about using one's talents and resources to bring light into the world. With each design she created for Sankalp, she felt a deep sense of fulfillment—a feeling that no paycheck could ever provide.`,
      },
      {
        p10: `Chanda Sahani was not just a graphic designer; she was a symbol of how one person’s talent, when combined with a compassionate heart, could create waves of change in the lives of others. She proved that even the busiest professionals could make a lasting impact on society, showing us all that true success lies in giving back and helping those in need.`,
      },
    ],
  },
  {
    id: 3,
    name: "Siddharth",
    role: "Chairman",
    image: "/img/about/pahuna.jpg",
    Qualifications: "Bachelor ",
    Specialization: "Leadership",
    bio: [
      {
        p1: `Siddharth had always been a dreamer. From a young age, he found himself inspired by stories of social change and the incredible work that individuals were doing to uplift communities. He was no stranger to hard work, and his passion for leadership and team management began to shape his journey even during his time in college. Graduating with a bachelor's degree, Siddharth knew that his true calling lay not in boardrooms or corporate offices, but in the heart of communities that needed help the most.`,
      },
      {
        p2: `After completing his education, Siddharth joined Sankalp Social Trust, an NGO dedicated to bringing about lasting change in underprivileged areas. He was drawn to their mission: to empower marginalized communities by providing resources, education, and leadership training. Sankalp’s work resonated deeply with him because it aligned with his vision of using leadership skills to make a difference.`,
      },
      {
        p3: `From the very first day, Siddharth was thrust into a leadership role. While he was relatively new to the organization, his ability to connect with people, inspire trust, and encourage collaboration quickly earned him the respect of his colleagues and community members. He became a key player in managing a variety of initiatives—whether it was organizing workshops, leading teams to distribute food and supplies, or helping implement educational programs in remote areas.`,
      },
      {
        p4: `One of Siddharth’s most notable achievements at Sankalp was his leadership in a project aimed at providing vocational training to young women in rural villages. The project sought to equip these women with the skills to start their own businesses, be it in tailoring, handicrafts, or food production. Initially, there were many challenges—skepticism from the women, logistical hurdles, and even cultural resistance. But Siddharth, with his team, worked tirelessly, day and night, to build rapport, gain trust, and understand the unique needs of each individual. His leadership wasn’t about giving orders but about listening, empathizing, and guiding.`,
      },
      {
        p5: `Through his guidance, the project flourished. Women who once felt invisible and voiceless were now confident entrepreneurs, able to sustain themselves and contribute to their families’ incomes. They no longer saw themselves as victims of circumstance but as agents of change. As these women began to flourish, the ripple effect was felt throughout the community. More families were able to access education, healthcare, and a better standard of living. The village slowly transformed from a place where poverty was rampant to a place where hope and opportunity thrived.`,
      },
      {
        p6: `But Siddharth’s journey at Sankalp was never without its difficulties. There were days when he felt overwhelmed, facing moments of self-doubt, particularly when progress seemed slow, or when resources were limited. Yet, he always found a way to push forward, driven by the belief that his work was a vital part of the larger effort to bring social change. His leadership became not just about achieving success, but about resilience, humility, and learning from every setback.`,
      },
      {
        p7: `Today, Siddharth stands as one of the pillars of Sankalp Social Trust. The NGO has grown under his leadership, expanding its reach to more villages, empowering countless individuals, and creating sustainable change in the lives of those who once felt forgotten. Siddharth’s story is not just one of professional growth, but of a deep, unwavering commitment to the power of leadership in transforming society.`,
      },
      {
        p8: `Looking back, Siddharth knew that the true measure of success wasn’t in accolades or recognition, but in the lives touched, the communities uplifted, and the belief that, with the right leadership, every challenge could be turned into an opportunity for change.`,
      },
    ],
  },
  {
    id: 4,
    name: "Krishna",
    role: "Event Manager",
    image: "/img/about/krishna.jpg",
    Qualifications: "Diploma in pharmacy",
    Specialization: "Drug expert",
    bio: [
    {
      p1:`Krishna Pandey was born and raised in the vibrant town of Gorakhpur, Uttar Pradesh, a place known for its rich culture and deep-rooted traditions. From a young age, Krishna had a keen interest in science, especially in the field of medicine. His curiosity about how different substances could heal the human body led him to pursue a Diploma in Pharmacy, where he quickly became known for his sharp mind and an ever-growing desire to make a difference.`
    },
    {
      p2:`After completing his diploma, Krishna didn’t settle for just a conventional career. He was determined to specialize as a drug expert, gaining deep insights into the chemistry behind pharmaceuticals and how they could be used to treat various ailments. His fascination with the healing power of medicine, however, wasn’t limited to the science alone. Krishna realized that the true purpose of his expertise lay in helping people, especially the underserved communities.`
    },
    {
      p3:`Gorakhpur, like many other parts of Uttar Pradesh, faced its share of challenges. Access to quality healthcare was limited in many rural areas, and people often had to make do with substandard medications. This gap between the needs of the people and the healthcare system weighed heavily on Krishna’s heart.`
    },
    {
     p4:`In a bid to make a tangible difference, Krishna decided to channel his expertise into the service of others. He joined the Sankalp Social Trust, an NGO dedicated to improving healthcare access for marginalized communities. The trust’s mission was clear: to provide free medical aid, medicines, and awareness about health and wellness to those who needed it the most.`
    },
    {
      p5:`Krishna’s role in Sankalp was pivotal. With his knowledge of pharmaceuticals, he became the drug expert the organization needed. He was responsible for ensuring that the medicines distributed through Sankalp were not only effective but also safe and affordable for the people who relied on them. He worked tirelessly to maintain a network of local doctors, pharmacists, and volunteers who helped extend the reach of the organization’s health initiatives.`
    },
    {
      p6:`One of Krishna’s most rewarding projects was in the remote village of Rajapur, located on the outskirts of Gorakhpur. Here, the villagers had little access to proper medical care. Krishna, along with the Sankalp team, conducted health camps where they provided free checkups, distributed essential medicines, and educated people on basic hygiene and preventive healthcare.`
    },
    {
      p7:`During one of these camps, a young mother came to Krishna with her sick child. The child had been suffering from a fever for days, and despite taking some over-the-counter medication, the fever showed no sign of relenting. After examining the child and reviewing the history, Krishna realized that the fever was a symptom of a much more serious infection. He quickly prescribed the appropriate treatment, and within days, the child was back to health.`
    },
    {
      p8:`The grateful mother looked at Krishna with tears in her eyes and said, "You’ve saved my child’s life." That moment encapsulated the reason why Krishna had chosen this path. It wasn’t about prestige or recognition; it was about changing lives, one person at a time.`
    },
    {
      p9:`As Krishna continued his work with Sankalp Social Trust, he also collaborated with other healthcare professionals and NGOs to strengthen the trust’s reach and resources. Through workshops, seminars, and public health campaigns, he worked to create awareness about essential drugs, their proper usage, and the importance of safe and affordable healthcare for all.`
    },
    {
      p10:`Today, Krishna Pandey is not just a drug expert; he is a beacon of hope, demonstrating how specialized knowledge in pharmacy can be used to uplift entire communities. His work with Sankalp Social Trust continues to touch the lives of many, and he remains steadfast in his mission to bridge the gap in healthcare access for the underserved.

Krishna’s story is a reminder that even in the face of adversity, one individual’s dedication and expertise can make a world of difference.`
    }
     
  
    ],
  },
];

const TeamPersonDetail = () => {
  const { id } = useParams();
  const member = teamMembers.find((member) => member.id == id);
   // Scroll to the top of the page when the component is mounted
   useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!member) {
    return <h2 className="text-center text-2xl">Member not found</h2>;
  }

  return (
    <>
      <p className="m-3">
        <Link to="/team" className="text-blue-700 font-semibold">
          Back
        </Link>
      </p>

      <div className="max-w-6xl mx-auto p-4 text-center bg-white shadow-lg rounded-lg">
        <img
          src={member?.image}
          alt={member?.name}
          className="rounded-full w-60 h-60 mx-auto mb-4 border-4 border-teal-500"
        />
        <h1 className="text-3xl font-extrabold">{member?.name}</h1>
        <h2 className="text-lg font-semibold text-teal-600 mt-2">
          {member?.role}
        </h2>
        <div className="flex justify-around flex-col lg:flex-row mt-2">
          <p className="text-sm font-medium">
            Qualifications : {member?.Qualifications}
          </p>
          <p className="text-sm font-medium">
            Specialization : {member?.Specialization}
          </p>
        </div>

        {/* Bio Section */}
        <div className="mt-8 space-y-6 text-left text-lg leading-relaxed">
          {member?.bio?.map((bioParagraph, index) => (
            <p key={index} className="text-gray-700">
              {Object.values(bioParagraph)[0]}
            </p>
          ))}
        </div>

        {/* Social media links with icons */}
        <div className="flex justify-center mt-8 gap-6">
          <a
            href={member?.social?.facebook}
            target="_blank"
            rel="noopener noreferrer"
            className="text-2xl text-blue-600 hover:text-teal-500"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href={member?.social?.linkedin}
            target="_blank"
            rel="noopener noreferrer"
            className="text-2xl text-blue-600 hover:text-teal-500"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a
            href={member?.social?.github}
            target="_blank"
            rel="noopener noreferrer"
            className="text-2xl text-gray-700 hover:text-teal-500"
          >
            <i className="fab fa-github"></i>
          </a>
          <a
            href={member?.social?.twitter}
            target="_blank"
            rel="noopener noreferrer"
            className="text-2xl text-blue-400 hover:text-teal-500"
          >
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default TeamPersonDetail;
