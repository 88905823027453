

import axios from "axios";
import React, { useEffect, useState } from "react";

const Member = () => {
  const [alluser, setAlluser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6); // Number of items per page
  const [loader,setLoader]=useState(false)

  const getAllUsers = async () => {
    setLoader(true)
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/getallusers`
      );
      setAlluser(res.data);
      setLoader(false)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  // Calculate the index of the first and last items on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = alluser.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Create pagination buttons
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(alluser.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div className="text-center font-bold text-lg text-gray-300 bg-teal-700 ">
        <p className="p-1">All Members </p>
      </div>
      <p className='font-bold text-center text-md text-blue-500 p-2'>{loader?"Loading...":""}</p>
      <div className="grid grid-cols-3 text-center gap-2 m-1 md:grid-cols-4 lg:grid-cols-6">
        {currentItems.map((res, i) => (
          <div
            key={i}
            className="bg-gray-100 shadow-md rounded-sm cursor-pointer my-1 "
          >
            <img
              src={res.profile}
              alt="img"
              className="h-24 w-24 rounded-full m-auto flex justify-center lg:h-32 lg:w-32 mt-1"
            />
            <div className="my-3">
              <p className="mt-2 text-sm font-semibold text-gray-600">
                {res.name}
              </p>
              <i className="text-xs font-semibold text-blue-600">
                {res.isAdmin ? "Admin" : "Member"}
              </i>
              <div className="flex justify-between items-center mt-2">
                <a
                  href={res?.socialMedia?.facebook || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="./img/icons/f.png"
                    alt="logo"
                    className="h-3 cursor-pointer mx-2"
                  />
                </a>
              
                <a
                  href={res?.socialMedia?.youtube || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="./img/icons/y.png"
                    alt="logo"
                    className="h-3 cursor-pointer mx-2"
                  />
                </a>
                <a
                  href={res?.socialMedia?.linkedin || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="./img/icons/i.png"
                    alt="logo"
                    className="h-3 cursor-pointer mx-2"
                  />
                </a>
                <a
                  href={res?.socialMedia?.x || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="./img/icons/x.png"
                    alt="logo"
                    className="h-3 cursor-pointer mx-2"
                  />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-2 py-1 mx-1 rounded ${
              currentPage === number ? "bg-teal-700 text-white" : "bg-gray-200"
            }`}
          >
            {number}
          </button>
        ))}
      </div>
    </>
  );
};

export default Member;
