import React from 'react'

const Footer = () => {
  return (
    <div className='flex flex-col justify-center items-center bg-gray-600 py-2 mt-4 text-gray-400 md:flex-row md:justify-around'>
      <div>
       <p className='text-center font-bold'>Contact</p>
       <p className='text-xs text-center'>Email:- sankalpsocialtrust.help@gmail.com</p>
       <p className='text-xs text-center'>Mob:-  +91 8115784664</p>
       
       
      </div>
      <div>
      <div className='flex justify-between items-center p-2'>
        
        <a href="https://www.facebook.com/share/1Cin7yPnGJ" target='_blank'><img src="./img/icons/f.png" alt="logo" className='h-6 cursor-pointer mx-2' /></a>
        <a href="https://www.youtube.com/@sankalpsocialtrust" target='_blank'><img src="./img/icons/y.png" alt="logo" className='h-6 cursor-pointer mx-2' /></a>
       <a href="https://www.linkedin.com/in/karan-kumar-sahani-413360153?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target='_blank'> <img src="./img/icons/i.png" alt="logo" className='h-6 cursor-pointer mx-2' /></a>
       <a href=""><img src="./img/icons/x.png" alt="logo" className='h-6 cursor-pointer mx-2' /></a>
       
        
        </div>
        <p className='text-xs text-center'>All rights reserved Sankalp Social Trust</p>
       <p className='text-xs text-center'> <a href="/" >www.sankalpsocialtrust.org</a></p>
        <p className='text-xs mb-4 text-center'>Developed by Karan</p>
      </div>
      <div className='text-sm'>
        <p className='text-center font-bold'>Head Office</p>
        <p className='text-xs'>Post Tetari Bazar Siddharth Nagar</p>
        <p className='text-xs'>Uttar Pradesh Pincode-272207</p>
      </div>

    </div>
  )
}

export default Footer