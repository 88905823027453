import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const AdminProfile = () => {
  const data = localStorage.getItem("userData");
  const userData = JSON.parse(data);
  let name = userData?.name;
  let admin = userData?.admin;

  const [totalViews, setTotalViews] = useState();
  const [loading, setLoading] = useState(true);
  const Navigate = useNavigate();

  useEffect(() => {
    if (!admin == "true") {
      Navigate("/");
    }
  }, []);

  const logoutFun = () => {
    localStorage.clear();
    Navigate("/");
  };




  
 

 

  useEffect(() => {
    const totalViewsFun = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/get/totalviews`);
             console.log("total views",response.data.totalviews)
             setTotalViews(response?.data?.totalviews)
        } catch (err) {
            console.error("Error fetching messages:", err);
        }
    };



    totalViewsFun();
   

}, []);

  

  return (
    <>
      <div className="flex justify-end m-2">
        <p className="text-gray-500 font-bold mr-4">{"Hello " + name}</p>
        <button
          onClick={logoutFun}
          className="bg-red-600 m-auto text-white mx-2 p-1 px-2 text-xs font-semibold cursor-pointer rounded-sm focus:outline-none focus:shadow-outline"
        >
          Logout
        </button>
      </div>

      <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-2  m-1 p-1 text-xs md:text-md lg:text-lg font-semibold text-center md:mb-10">
        <Link
          to="/createblog"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/blog.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Create Blog</p>
        </Link>
        <Link
          to="/manageblog"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/event.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Yore Blogs</p>
        </Link>
        <Link
          to="/update-user-profile"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/program.jpeg"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Edit Profile</p>
        </Link>
        <Link
          to="/admincreatevent"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/createEvent.avif"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Create Event</p>
        </Link>
        <Link
          to="/adminallpost"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/allblogs.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">AllPost</p>
        </Link>
        <Link
          to="/adminallusers"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/Volunteers.jpg"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">AllUser</p>
        </Link>
        <Link
          to="/adminmanageevent"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/allEvent.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48 p-1"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Event</p>
        </Link>
        <Link
          to="/admin-all-donation"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/donation.jpg"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Donation</p>
        </Link>
        <Link
          to="/child-education-page"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/services.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48 p-4"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Services</p>
        </Link>
        <Link
          to="/message/notification"
          className="shadow-md flex flex-col justify-centeritem-center cursor-pointer hover:opacity-80"
        >
          <img
            src="./img/icons/notification.png"
            alt="img"
            className="h-14 md:h-32 lg:h-48"
          />
          <p className="p-1 bg-teal-200 rounded-sm text-xs">Notification</p>
        </Link>
      </div>


      <div className="flex justify-center space-x-4 mt-6">
  <p className="p-2 text-gray-600 rounded-sm shadow-lg text-sm font-bold transition-transform duration-200 transform ">
    Total Visitors : {totalViews}
  </p>
 
</div>


 
    </>


  );
};

export default AdminProfile;
