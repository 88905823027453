import axios from "axios";
import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";

const Event = () => {
  const [allevent, setAllevent] = useState();
  const [loader, setLoader] = useState(false);

  const getAllEventFun = async () => {
    setLoader(true);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/getallevent`
      );

      // Check if data is present and is an array
      if (Array.isArray(res.data)) {
        const activeEvent = res.data.filter((item) => item?.status === true);
        setAllevent(activeEvent);
      } else {
        console.warn("Unexpected data format:", res.data);
        // Optionally setAllevent([]) or handle the case where data is not an array
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      // Optionally display an error message to the user
      
    } finally {
      setLoader(false); // Stop loader regardless of success or failure
    }
  };

  useEffect(() => {
    getAllEventFun();
  }, []);
  return (
    <>
      {allevent && allevent?.length > 0 ? (
        <div className="text-center text-lg font-bold mb-1 text-gray-300 bg-teal-700 py-2">
          <p>Upcoming Social Event</p>
        </div>
      ) : (
        ""
      )}

      <p className="font-bold text-center text-md text-blue-500 p-2">
        {loader ? "Loading..." : ""}
      </p>
      <div className="grid grid-cols-1 text-start gap-2 m-1 md:grid-cols-4">
        {allevent?.map((res, i) => (
          <div className="bg-gray-100 shadow-md rounded-sm cursor-pointer my-1 ">
            <Link to={`/admineventails/${res._id}`} key={i}>
              <img
                src={res?.postimg[0]}
                alt="img"
                className="hover:opacity-80 h-64 lg:h-60 w-full"
              />
            </Link>
            <div className="my-3">
              <p className="my-2 ml-2 text-md font-bold text-gray-600">
                {res?.title?.length > 38
                  ? `${res?.title.substring(0, 38)}...`
                  : res?.title}
              </p>
              <p className="my-2 ml-2 text-xs font-semibold text-teal-700">
                <b>Date :- </b>
                {dateFormat(res.eventdate, "dddd, mmmm dS, yyyy")}{" "}
                <b>Time :- </b>
                {res.eventtime}
              </p>
              <p className="my-2 ml-2 text-sm font-semibold  text-blue-800">
                <b>Location :- </b> {res.location}
              </p>
              <div className="flex justify-around items-center">
                <Link
                  to={`/payment/${res._id}`}
                  className="bg-red-500 text-center font-semibold cursor-pointer p-1 px-2 rounded-sm text-white mt-3 hover:bg-red-600"
                >
                  Donate Now
                </Link>
                <p className="bg-red-500 text-center font-semibold cursor-pointer p-1 px-2 rounded-sm text-white mt-3 hover:bg-red-600">
                  <a
                    href="https://www.youtube.com/@sankalpsocialtrust"
                    target="_blank"
                  >
                    Watch Video
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Event;
